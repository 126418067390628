import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"
import React from "react"
import styled from "styled-components"
import judgePage from "../judgePage"
import Footer from "../PC/organisms/Footer"
import Header from "../PC/organisms/Header"
import Hero from "../PC/organisms/Hero"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import SEO from "../seo"
import MobileFooter from "../mobile/organisms/MobileFooter"
import MobileHeader from "../mobile/organisms/MobileHeader"
import MobileHero from "../mobile/organisms/MobileHero"

const ServiceSEOData = {
    title: "コンタクト",
    lang: "jp",
    url: "/contact",
    description:
        "製造業、建設業、小売業などの労働集約型旧態産業において、デジタルトランスフォーメーションで産業に変革を起こすテクノロジー集団",
}

const Root = styled.div`
    display: inline-block;
    width: 100%;
`

const PCContent = styled.div`
    h1 {
        font-size: 28px;
        font-weight: normal;
        margin: 56px 0;
        text-align: center;
    }
    p.page-description {
        font-size: 18px;
        margin-bottom: 48px;
        text-align: center;
        font-weight: normal;
    }
    form {
        width: 720px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        label {
            font-size: 16px;
        }
        dl {
            display: grid;
            grid-template-columns: 220px 500px;
        }
        dt {
            width: 220px;
            padding-top: 14px;
            &.select-inquiry-items {
                padding-top: 0;
            }
        }
        dd {
            margin-bottom: 48px;
            width: 500px;
            &#Select-Inquiry-Items {
                display: flex;
                flex-direction: column;
                label {
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;
                    input[type="radio"] {
                        display: inline-block;
                        margin-right: 8px;
                    }
                }
            }
            input[type="text"],
            textarea {
                background-color: #f1f2f6;
                padding: 16px;
                width: 100%;
                border: none;
                &::placeholder {
                    color: #c4c4c4;
                    font-weight: normal;
                }
            }
        }
        button {
            border: #b2b2b2 solid 1px;
            background-color: #ffffff;
            font-size: 22px;
            font-weight: bold;
            padding: 39px 0 36px;
            display: flex;
            align-items: flex-end;
            width: 280px;
            line-height: 1;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 100px;
            justify-content: center;
            .button-inner {
                margin-left: 12px;
                display: flex;
                align-items: flex-end;
            }
        }
        .required-optional-label::after {
            content: "※必須";
            color: #bd9e81;
            font-size: 12px;
            margin-left: 8px;
        }
    }
`

const SPContent = styled.div`
    padding: 0 16px;
    h1 {
        font-size: 20px;
        font-weight: normal;
        margin: 40px auto 32px;
        text-align: left;
    }
    p.page-description {
        font-size: 16px;
        margin-bottom: 32px;
        text-align: center;
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
    }
    form {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        label {
            font-size: 16px;
        }
        dl {
        }
        dt {
            width: 100%;
            margin-bottom: 16px;
            &.select-inquiry-items {
                padding-top: 0;
            }
        }
        dd {
            margin-bottom: 40px;
            width: 100%;
            &#Select-Inquiry-Items {
                display: flex;
                flex-direction: column;
                label {
                    margin-bottom: 16px;
                    display: flex;
                    align-items: center;
                    input[type="radio"] {
                        display: inline-block;
                        margin-right: 8px;
                    }
                }
            }
            input[type="text"],
            textarea {
                background-color: #f1f2f6;
                padding: 16px;
                width: -webkit-fill-available;
                border: none;
                &::placeholder {
                    color: #c4c4c4;
                    font-weight: normal;
                }
            }
        }
        .button-wrapper {
            padding: 0 4px;
        }
        button {
            border: #b2b2b2 solid 1px;
            background-color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            padding: 21px 0 19px;
            display: flex;
            align-items: flex-end;
            width: 100%;
            line-height: 1;
            margin-bottom: 100px;
            justify-content: center;
            .button-inner {
                margin-left: 12px;
                display: flex;
                align-items: center;
                svg {
                    margin-left: 6px;
                    margin-bottom: 1px;
                }
            }
        }
        .required-optional-label::after {
            content: "※必須";
            color: #bd9e81;
            font-size: 12px;
            margin-left: 8px;
        }
    }
`

const PageContent = () => {
    return (
        <>
            <h1>フォームよりご連絡ください。</h1>
            <p className="page-description">
                ご依頼やご相談がございましたら、以下のフォームよりお気軽にお問い合わせください。
            </p>
            <form
                method="post"
                action='https://getform.io/f/fcd235f7-648d-4297-b4b0-a3ae79d04b47'
            >
                <dl>
                    <dt>
                        <label className="required-optional-label">
                            お名前(氏名)
                        </label>
                    </dt>
                    <dd>
                        <input
                            type="text"
                            name='name'
                            placeholder="例）田中 太郎"
                            required
                        />
                    </dd>

                    <dt>
                        <label className="required-optional-label">
                            会社名
                        </label>
                    </dt>
                    <dd>
                        <input
                            type="text"
                            name='company'
                            placeholder="例）株式会社クアンド"
                            required
                        />
                    </dd>

                    <dt>
                        <label className="required-optional-label">
                            メールアドレス
                        </label>
                    </dt>
                    <dd>
                        <input
                            type="text"
                            name='email'
                            placeholder="例）info@quando.jp"
                            required
                        />
                    </dd>

                    <dt>
                        <label className="required-optional-label">
                            電話番号
                        </label>
                    </dt>
                    <dd>
                        <input
                            type="text"
                            name='tel'
                            placeholder="例）000-0000-0000"
                            required
                        />
                    </dd>
                    <dt className="select-inquiry-items required-optional-label">
                        お問い合わせ項目
                    </dt>
                    <dd id="Select-Inquiry-Items">
                        <label>
                            <input
                                type="radio"
                                name="inquiryPurpose"
                                value="製品・サービスについて"
                                id="Products-and-Services"
                                required
                            />
                            製品・サービスについて
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="inquiryPurpose"
                                value="講演・取材の依頼"
                                id="Lecture-and-interview-requests"
                            />
                            講演・取材の依頼
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="inquiryPurpose"
                                value="採用について"
                                id="About-Recruitment"
                            />
                            採用について
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="inquiryPurpose"
                                value="その他"
                                id="other"
                            />
                            その他
                        </label>
                    </dd>

                    <dt>
                        <label className="required-optional-label">
                            お問い合わせ内容
                        </label>
                    </dt>
                    <dd>
                        <textarea
                            placeholder="お問い合わせ内容を入力"
                            name='inquiryContent'
                            rows="10"
                            cols="60"
                            required
                        ></textarea>
                    </dd>
                </dl>
                <div className="button-wrapper">
                    <button type="submit">
                        <span className="button-inner">
                            送信する
                            <ChevronRightIcon />
                        </span>
                    </button>
                </div>
            </form>
        </>
    )
}

const ContactTemplate = () => {
    const page = judgePage("CONTACT")
    const breakPoints = useBreakpoint()

    return (
        <Root>
            <SEO
                title={ServiceSEOData.title}
                lang={ServiceSEOData.lang}
                url={ServiceSEOData.url}
                description={ServiceSEOData.description}
            />
            {breakPoints.pc ? <PCView page={page} /> : null}
            {breakPoints.mobile ? <SPView page={page} /> : null}
        </Root>
    )
}

const PCView = ({ page }) => {
    return (
        <>
            <Hero page={page.name} />
            <PCContent>
                <PageContent />
            </PCContent>
            <Footer />
            <Header page={page.name} />
        </>
    )
}

const SPView = ({ page }) => {
    return (
        <>
            <MobileHero page={page.name} />
            <SPContent>
                <PageContent />
            </SPContent>
            <MobileFooter />
            <MobileHeader page={page.name} />
        </>
    )
}

export default ContactTemplate
